@mixin react-select($color) {
    .react-select__control {
        border-color: $color !important;
    }

    .react-select__control:hover {
        border-color: $color !important;
    }

    // .react-select__control:hover {
    //     box-shadow: 0 0 0 1px $success !important;
    // }
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-select__control {
    box-shadow: none !important;
}

.react-select-invalid {
    @include react-select($isuzu-color);
}

.react-select-valid {
    @include react-select($success);
}
