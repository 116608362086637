#nprogress .bar {
    background: $danger;
}

#nprogress .peg {
    box-shadow: 0 0 10px $danger, 0 0 5px $danger;
}

#nprogress .spinner-icon {
    border-top-color: $danger;
    border-left-color: $danger;
}
