.vertical-line {
    background-color: lightgrey;
    height: 100%;
    width: 1%;
}

.red-square { 
    background-color: #FF0000;
    width: 20px;
    height: 20px;
}

.yellow-square { 
    background-color: #FFC000;
    width: 20px;
    height: 20px;
}

.width-general-btn {
    width: 40%;
}