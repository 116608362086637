$sidebar-width: 250px;

.isuzu-logo {
    width: $sidebar-width;
}

.cms-sub-menu {
    width: 25px;
}

.cms-menu-icon {
    width: 35px;
}

.navbar-frame {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-logo {
    max-height: 50px;
}

.stacked-nav {
    .nav-link {
        color: #6c757d;
    }
}

.stacked-nav {
    .nav-link.active {
        color: #f8f9fa;
    }
}

.stacked-nav .nav-link:hover {
    text-decoration: underline;
}

.sidebar-frame {
    display: flex;
    flex-grow: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
}

.sidebar-frame {
    > main {
        margin-left: 0;
        transition: margin 0.5s;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    /* allow shrinking the main page width when sidebar is shown */
    .sidebar-frame > .sidebar-show + main {
        margin-left: $sidebar-width;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    /* always display sidebar */
    .sidebar-frame > main {
        margin-left: $sidebar-width;
    }
}

.sidebar {
    width: $sidebar-width;
    height: calc(100vh - 82px);
    flex-shrink: 0;
    position: fixed;
    background-color: $danger;
    overflow-x: hidden;
    overflow-y: auto;
    transition: margin 0.5s;

    border-top-right-radius: 0.3rem;
    z-index: 999;
    // top: 192.98px;
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    /* hide sidebar on tablets, but keep desktop-sized sidebar */
    .sidebar-hide {
        margin-left: -100vw;
    }

    .sidebar {
        height: calc(100vh - 130px);
        box-shadow: 2px;
    }

    .isuzu-logo {
        width: 100vw;
    }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    /* hide sidebar on mobile and let it take up the entire viewport when expanded */
    .sidebar {
        width: 100vw;
        position: fixed;
        border-top-right-radius: 0;
    }
}
