$isuzu-color: #ff0000;
$danger: $isuzu-color;
$zindex-modal: 1059;

// pagination color
$pagination-color: $danger;

$pagination-focus-color: $danger;
$pagination-focus-bg: #fff;
$pagination-focus-box-shadow: none;

$pagination-hover-color: $danger;
$pagination-active-bg: $danger;

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here

$min-contrast-ratio: 3.99;

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/root";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// 5. Add additional custom code here

@import 'nprogress/nprogress.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

// @import './default-layout.scss';
@import './font.scss';
@import './cms-layout.scss';
@import './page-loading.scss';
@import './inputs.scss';
@import './general-dashboard-layout.scss';
.delete-button {
    background-color: #830b0b;
}